/* styles.css */

/* Animation for the header */
@keyframes slideUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* Animation for the welcome box */
 /* Style.css */
.welcome-slide-in {
  animation: slideIn 0.5s forwards; /* Trigger the animation */
}

@keyframes slideIn {
  0% {
      transform: translateX(-10%); /* Start off-screen to the left */
      opacity: 0; /* Invisible at the start */
  }
  100% {
      transform: translateX(0); /* End at the original position */
      opacity: 1; /* Fully visible */
  }
}
  /* Apply the animations */
  .header-slide-up {
    animation: slideUp 0.3s forwards;
  }
  
  .header-slide-down {
    animation: slideDown 0.3s forwards;
  }
  
  .welcome-slide-in {
    animation: slideIn 0.5s forwards;
  }
  

  .fade-enter {
    opacity: 0;
    transform: translateY(10px); /* Optional: add some movement */
  }
  
  .fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms; /* Duration of the transition */
  }
  
  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: translateY(-10px); /* Optional: reverse movement */
    transition: opacity 300ms, transform 300ms; /* Duration of the transition */
  }